export const formatDate = (date: string | null) => {
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
  return null;
};
