import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Account,
  Clinician,
  DashboardState,
  Patient,
  UpdatePatientPayload,
} from './types';
import { formatDate } from 'utils/formatDate';
import { RootState } from '../store';
import { authSlice } from '../auth/slice';

const initialDashboardState: DashboardState = {
  account: null,
  patients: null,
  totalCount: null,
};

export const dashboardSlice = createSlice({
  name: 'auth',
  initialState: initialDashboardState,
  reducers: {
    setAccount: (state, action: PayloadAction<Account>) => {
      state.account = action.payload;
    },
    setClinicians: (state, action: PayloadAction<Clinician[]>) => {
      if (state.account?.clinic) {
        state.account.clinic.clinicians = action.payload;
      }
    },
    setPatients: (
      state,
      action: PayloadAction<{ patients: Patient[]; rewrite?: boolean }>
    ) => {
      if (state.patients === null || action.payload.rewrite) {
        state.patients = [];
      }
      state.patients = [...state.patients, ...action.payload.patients];
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    updatePatient: (state, action: PayloadAction<UpdatePatientPayload>) => {
      // take link to patient by report id
      const patient = state.patients?.find(
        (patient) => patient.id === action.payload.patientId
      );
      if (patient !== undefined) {
        if (patient.report === null) {
          patient.report = {} as Patient['report'];
        }
        // mutate patient by link to make redux toolkit update data
        patient.report!.approvalStatus = action.payload.approvalStatus;
        patient.report!.approver = action.payload.approver;
        patient.report!.approvalDate = formatDate(action.payload.approvalDate);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.logout, (state) => {
      state.account = null;
    });
  },
});

export const { setAccount, setClinicians, setPatients, setTotalCount, updatePatient } =
  dashboardSlice.actions;

export const selectAccount = (state: RootState) => {
  return state.dashboard.account;
};

export const selectClinic = (state: RootState) => {
  return state.dashboard.account?.clinic ?? null;
};

export const selectPatients = (state: RootState) => {
  return state.dashboard.patients;
};

export const selectTotalCount = (state: RootState) => {
  return state.dashboard.totalCount;
};

export const dashboardReducer = dashboardSlice.reducer;
