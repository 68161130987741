import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AccountSetupState, ActivationUrlParams } from './types';

const initialAuthState: AccountSetupState = {
  activationUrlParams: undefined,
  password: undefined,
  phoneNumber: undefined,
};

export const accountSetupSlice = createSlice({
  name: 'accountSetup',
  initialState: initialAuthState,
  reducers: {
    setActivationUrlParams: (
      state,
      action: PayloadAction<ActivationUrlParams>
    ) => {
      state.activationUrlParams = action.payload;
    },
    setAccountSetupPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setAccountSetupPhone: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
  },
});

export const {
  setActivationUrlParams,
  setAccountSetupPassword,
  setAccountSetupPhone,
} = accountSetupSlice.actions;

export const selectActivationUrlParams = (state: RootState) => {
  return state.accountSetup.activationUrlParams;
};

export const selectAccountSetupPhoneNumber = (state: RootState) => {
  return state.accountSetup.phoneNumber;
};

export const selectAccountSetupPassword = (state: RootState) => {
  return state.accountSetup.password;
};

export const accountSetupReducer = accountSetupSlice.reducer;
