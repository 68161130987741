import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
  getExampleNumber,
  PhoneNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

const US_ALPHA_2_CODE = 'US';

export type CountryObj = {
  code: string;
  name: string;
  phoneCode: string;
  phoneNumberExample?: PhoneNumber;
  flag: string;
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
const countryToFlag = (isoCode: string): string => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

const getCountryObj = (countryAlpha2Code: string): CountryObj => {
  if (!countries.isValid(countryAlpha2Code)) {
    throw new Error('Alpha2 value probably changed, please check!');
  } else {
    // TODO this shouldn't be required, maybe investigate it later
    countries.registerLocale(enLocale);

    return {
      code: countryAlpha2Code,
      name: countries.getName(countryAlpha2Code, 'en', {
        select: 'official',
      }),
      phoneCode: `+${getCountryCallingCode(countryAlpha2Code as CountryCode)}`,
      flag: countryToFlag(countryAlpha2Code),
      phoneNumberExample: getExampleNumber(
        countryAlpha2Code as CountryCode,
        examples
      ),
    };
  }
};

export const DEFAULT_COUNTRY_OBJ = getCountryObj(US_ALPHA_2_CODE);

countries.registerLocale(enLocale);
const phoneAsYouType = new AsYouType();

export { countries, phoneAsYouType };
