import React, { useEffect, useState } from 'react';
import { Container, Link } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import {
  useGetAccountDataQuery,
  useGetPatientsQuery,
  useUpdateStatusMutation,
} from 'redux/dashboard/api';
import {
  selectAccount,
  selectClinic,
  selectPatients,
} from 'redux/dashboard/slice';
import { ReportStatus } from 'redux/dashboard/types';
import { Navbar } from 'components/navbar';
import { ErrorBox } from 'components/errorBox';
import { ApproveDialog } from './ApproveDialog';
import { ControlLine } from './ControlLine';
import { PatientsTable } from './PatientsTable';
import { TableFiltersType, PatientCoreData } from './types';

export const Dashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<TableFiltersType>({
    id: '',
    firstName: '',
    lastName: '',
    myPatientsOnly: true,
  });

  const account = useAppSelector(selectAccount);
  const clinic = useAppSelector(selectClinic);
  const patients = useAppSelector(selectPatients);

  const { isLoading: isAccountDataLoading, isError: isAccountDataError } =
    useGetAccountDataQuery();

  // wait for successful call to /me before fetching patients
  const delayPatientsRequest =
    isAccountDataLoading || isAccountDataError || clinic == null;

  /*
   * `isFetching` indicates loading state for each repeated request
   * `isLoading`  indicates first request loading state
   *
   * All patient request are called in Dashboard component.
   * `isLoading` flag is used to indicate first call that will show first page of patients.
   * This loading flag along with other critical api calls is used to handle a page-level loading(LinearProgress under AppBar)
   *
   * `useGetPatientsQuery` is called with `refetchOnMountOrArgChange` to ignore cached data.
   * query cannot be used inside Table component to not produce duplicated requests
   */
  const {
    isLoading: isPatientListLoading,
    isUninitialized: isPatientListUninitialized,
    isFetching: isPatientListFetching,
  } = useGetPatientsQuery(
    {
      clinicSlug: clinic?.slug ?? '',
      page: page,
      filters: filters,
    },
    {
      skip: delayPatientsRequest, // wait for clinic to be loaded
      refetchOnMountOrArgChange: true, // ignore cache because we rewrite patients when filter changes
    }
  );

  // isPatientListUninitialized flag is used to make sure
  // dashboard does NOT render table before it's query runs for the first time
  const isLoading =
    isAccountDataLoading || isPatientListLoading || isPatientListUninitialized;
  const isError = isAccountDataError;

  const [
    updateStatus,
    {
      isLoading: isUpdateStatusLoading,
      isSuccess: isUpdateStatusLoadingSuccess,
    },
  ] = useUpdateStatusMutation();

  const [patientData, setPatientData] = useState<PatientCoreData | null>(null);
  const handleOpenApproveDialog = (patientData: PatientCoreData) => {
    setPatientData(patientData);
    setIsModalOpen(true);
  };
  const handleStatusChange = (status: ReportStatus) => {
    // update patient in the list with patientId
    // updating fields: status, approveDate, approver
    if (account !== null && clinic !== null) {
      updateStatus({
        reportId: patientData!.reportId,
        clinicSlug: clinic.slug,
        approvalStatus: status,
        payload: {
          // handleStatusChange cannot be called if patientData is missing
          patientId: patientData!.patientId,
          approvalStatus: status,
          approvalDate: new Date().toISOString(),
          approver: account.fullName,
          name: patientData!.fullName,
        },
      });
    }
  };

  useEffect(() => {
    // if updating was successful close the modal
    // if updating fails remain popup open for next attempt or until user closes it by clicking "Cancel"
    if (isUpdateStatusLoadingSuccess) {
      setIsModalOpen(false);
    }
  }, [isUpdateStatusLoadingSuccess]);

  const onMyPatientsOnlyChange = () => {
    setFilters({
      ...filters,
      myPatientsOnly: !filters.myPatientsOnly,
    });
    setPage(1); // reset pagination if filter applied
  };

  return (
    <>
      <Navbar
        clinic={clinic?.name}
        clinician={account?.fullName}
        isLoading={isLoading}
      />
      <Container
        sx={{
          pt: 10,
          pb: 5,
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1316px',
        }}
        maxWidth={false}
      >
        {isError && (
          <ErrorBox
            message={
              <>
                Ooops! Something went wrong. Please contact{' '}
                <Link
                  href="mailto:help@tuune.com"
                  target="_blank"
                  color="inherit"
                >
                  help@tuune.com
                </Link>
              </>
            }
          />
        )}
        {!isError && !isLoading && (
          <>
            <ControlLine
              myPatientOnly={filters.myPatientsOnly}
              handleMyPatientsOnlyToggle={onMyPatientsOnlyChange}
            />

            <PatientsTable
              patients={patients}
              handleOpenApproveDialog={handleOpenApproveDialog}
              page={page}
              setPage={setPage}
              isFetching={isPatientListFetching}
            />

            {patientData && (
              <ApproveDialog
                open={isModalOpen}
                isLoading={isUpdateStatusLoading}
                patient={`${patientData.fullName} (${patientData.patientId})`}
                handleClose={() => setIsModalOpen(false)}
                defaultStatus={patientData.approvalStatus}
                handleSubmit={handleStatusChange}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};
