import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useLogoutMutation, useRefreshTokenMutation } from 'redux/auth/api';
import { selectIsAuthenticated } from 'redux/auth/slice';
import { useAppSelector } from 'redux/hooks';
import { routes } from 'routes';
import { getFromStorage } from 'utils/localStorage';
import { HAS_JWT_REFRESH_TOKEN } from './constants';

export const PrivateRoute: React.FC = () => {
  const navigate = useNavigate();
  const hasJwtRefresh = getFromStorage(HAS_JWT_REFRESH_TOKEN);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const [refreshToken, { isUninitialized, isSuccess, isLoading }] =
    useRefreshTokenMutation();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    if (hasJwtRefresh && !isAuthenticated && isUninitialized) {
      refreshToken();
    }
  }, [refreshToken, isUninitialized, hasJwtRefresh, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(routes.login);
    }
  }, [navigate, isAuthenticated]);

  const renderPrivateRoute = () => {
    // The user is already logged in and the user account is already fetched,
    // no need to refresh the access token, just grant access to the route
    if (isAuthenticated) {
      return <Outlet />;
    }

    // No user is not logged in, there is no need to refresh the access token,
    // just redirect to the login page
    if (!hasJwtRefresh) {
      return <Navigate to={routes.login} replace />;
    }

    // The user was logged in, and we're currently trying to get an access token using
    // the existing 'tuune_refresh' JWT refresh cookie
    if (isLoading || isUninitialized) {
      return <>Loading...</>;
    }

    // The JWT refresh has been successful, an access token has been successfully granted
    if (isSuccess) {
      if (!isAuthenticated) {
        // Wait for the account data to be fetched first
        return <>Loading...</>;
      } else {
        // The user account data has been fetched, grant access to the route
        return <Outlet />;
      }
    } else {
      // The JWT refresh failed, logout the user and redirect to the login page
      logout();
      return <Navigate to={routes.login} replace />;
    }
  };

  return renderPrivateRoute();
};
