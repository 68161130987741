import { api, POSTMAN_MOCK_SERVER_BASE_URL } from 'redux/api';
import {
  CreateAppointmentPatientRequest,
  CreateAppointmentPatientResponse,
  CreateAppointmentRequest,
  CreateAppointmentUserRequest,
  CreateAppointmentUserResponse,
} from './types';
import { setAppointmentPatientId, setAppointmentUserId } from './slice';
import { GenericApiResponse } from 'redux/types';
import { displayErrorPopup } from 'redux/notifications/slice';
import { DEFAULT_ERROR_MESSAGE } from 'redux/notifications/constants';

const createAppointmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<
      CreateAppointmentUserResponse,
      CreateAppointmentUserRequest
    >({
      query: (requestBody) => ({
        url: process.env.REACT_APP_USE_MOCK_SERVER
          ? `${POSTMAN_MOCK_SERVER_BASE_URL}/auth/users/create`
          : 'auth/users/create',
        method: 'POST',
        // When using the mock server, overwrite credentials: 'include' to avoid a CORS error
        ...(process.env.REACT_APP_USE_MOCK_SERVER && { credentials: 'omit' }),
        body: {
          first_name: requestBody.firstName,
          last_name: requestBody.lastName,
          phone_number: requestBody.phoneNumber,
          country: requestBody.country,
          state: requestBody.state,
          email: requestBody.email,
          accept_marketing_and_research: requestBody.acceptMarketingAndResearch,
          user_type: 'patient',
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAppointmentUserId(data.data.user_id));
        } catch (error) {
          dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
        }
      },
    }),
    createPatient: builder.mutation<
      CreateAppointmentPatientResponse,
      CreateAppointmentPatientRequest
    >({
      query: (requestBody) => ({
        url: process.env.REACT_APP_USE_MOCK_SERVER
          ? `${POSTMAN_MOCK_SERVER_BASE_URL}/auth/patients/create`
          : 'auth/patients/create',
        method: 'POST',
        // When using the mock server, overwrite credentials: 'include' to avoid a CORS error
        ...(process.env.REACT_APP_USE_MOCK_SERVER && { credentials: 'omit' }),
        body: {
          user_id: requestBody.userId,
          clinic_slug: requestBody.clinicSlug,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAppointmentPatientId(data.data.patient_id));
        } catch (error) {
          dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
        }
      },
    }),
    createAppointment: builder.mutation<
      GenericApiResponse,
      CreateAppointmentRequest
    >({
      query: (requestBody) => ({
        url: process.env.REACT_APP_USE_MOCK_SERVER
          ? `${POSTMAN_MOCK_SERVER_BASE_URL}/auth/appointments/create`
          : 'auth/appointments/create',
        method: 'POST',
        // When using the mock server, overwrite credentials: 'include' to avoid a CORS error
        ...(process.env.REACT_APP_USE_MOCK_SERVER && { credentials: 'omit' }),
        body: {
          date: requestBody.date,
          patient_id: requestBody.patientId,
          clinician_id: requestBody.clinicianId
        },
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useCreatePatientMutation,
  useCreateAppointmentMutation,
} = createAppointmentApi;
