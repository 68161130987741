import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchBaseQueryError,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';

import { authStorage } from 'utils/authStorage';
import { logout } from './auth/slice';
import { AuthResponse } from './auth/types';
import { authApiUrls } from './auth/constants';
import {
  clearApiErrors,
  displayErrorPopup,
  setApiFieldsValidationError,
  setApiRequestValidationError,
} from './notifications/slice';
import {
  DEFAULT_ERROR_MESSAGE,
  SESSION_EXPIRED_MESSAGE,
} from './notifications/constants';
import camelcaseKeys from 'camelcase-keys';

const LOCAL_ENV = 'local';
const LOCAL_API_HOST = 'http://localhost:8000';

const currentEnv = process.env.REACT_APP_ENV || LOCAL_ENV;

const HOST_MAP: Record<string, string> = {
  prod: 'https://api.tuune.com',
  staging: 'https://api.staging.tuune.com',
  dev: 'https://api.dev.tuune.com',
  localDev: 'https://api.dev.tuune.com',
};

const baseApiHost =
  currentEnv === LOCAL_ENV ? LOCAL_API_HOST : HOST_MAP[currentEnv];

export const POSTMAN_MOCK_SERVER_BASE_URL =
  'https://9acdab8f-9dc0-46d6-9225-6efc933557d6.mock.pstmn.io';

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseApiHost}/api/v1`,
  prepareHeaders: (headers) => {
    const accessToken = authStorage.accessToken;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
  credentials: 'include',
});

type ApiErrorResponseData = {
  detail: string;
  nonFieldErrors: string[];
};

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // Clear all the previous API errors before every request
  api.dispatch(clearApiErrors());

  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    const apiResponseData = camelcaseKeys(
      result.error?.data as ApiErrorResponseData,
      {
        deep: true,
      }
    );

    switch (result.error.status) {
      case 401:
        // Login error, display the error message
        if (typeof args !== 'string' && args.url === authApiUrls.login) {
          api.dispatch(displayErrorPopup(apiResponseData.detail));
          return result;
        }

        // For any other 401 response, try to refresh the JWT token
        const refreshResult = await baseQuery(
          { url: authApiUrls.refreshToken, method: 'POST' },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          // Token refreshed successfully, retry the initial query
          authStorage.accessToken = (refreshResult.data as AuthResponse).access;
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
          api.dispatch(displayErrorPopup(SESSION_EXPIRED_MESSAGE));
        }

        break;
      case 400:
        // When getting a 400 Bad Request API response,
        // check for validation error messages (request or specific fields)
        if (apiResponseData.detail) {
          api.dispatch(displayErrorPopup(apiResponseData.detail));
        } else if (apiResponseData.nonFieldErrors) {
          api.dispatch(
            setApiRequestValidationError(
              apiResponseData.nonFieldErrors.join('\n')
            )
          );
        } else {
          // TODO if apiResponseData.error.code === 1
          //
          //    setApiFieldsValidationError(
          //   camelcaseKeys(result.error?.data as Record<string, []>, {
          //     deep: true,
          //   })
          // )
          api.dispatch(
            setApiFieldsValidationError(
              camelcaseKeys(result.error?.data as Record<string, []>, {
                deep: true,
              })
            )
          );
        }
        break;
      default:
        // TODO apiResponseData.error.code === 0 for success, !== 0 otherwise
        // display an error popup for apiResponseData.error.message
        if (apiResponseData.detail) {
          api.dispatch(displayErrorPopup(apiResponseData.detail));
        } else {
          api.dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
        }
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
});
