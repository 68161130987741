import React from 'react';
import { Button, Container, Stack, Typography } from '@mui/material';
import { routes } from 'routes';

export const PageNotFound: React.FC = () => {
  return (
    <Container fixed>
      <Stack py={17} direction="column" alignItems="start" rowGap={3}>
        <Typography variant="h2">Page not found</Typography>
        <Typography variant="body1">
          Unfortunately, the page you’re looking for doesn’t exist or there was
          an error in the link you followed or typed.
        </Typography>
        <Button href={routes.dashboard}>Go to dashboard</Button>
      </Stack>
    </Container>
  );
};
