import React, { useCallback, useEffect, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { routes } from 'routes';
import {
  AUTH_FLOW_PAGES_TITLE,
  PASSWORD_REQUIRE_LETTERS_REGEX,
  PASSWORD_REQUIRE_NUMBERS_REGEX,
} from './constants';
import { AuthLayout } from 'components/authLayout';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useActivateAccountMutation,
  useValidatePasswordMutation,
} from 'redux/accountSetup/api';
import { selectActivationUrlParams } from 'redux/accountSetup/slice';
import {
  clearApiErrors,
  displayErrorPopup,
  selectApiFieldsValidationErrors,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';
import { ErrorBox } from 'components/errorBox';
import { DEFAULT_ERROR_MESSAGE } from 'redux/notifications/constants';

export type SetPasswordFormData = {
  password: string;
  confirmPassword: string;
};

export const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activationUrlParams = useAppSelector(selectActivationUrlParams);
  useEffect(() => {
    if (!activationUrlParams) {
      dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
      navigate(routes.login);
    }
  }, [activationUrlParams, dispatch, navigate]);

  const [
    validatePassword,
    {
      isSuccess: isValidateSuccess,
      isLoading: isValidateLoading,
      data: validateResponse,
    },
  ] = useValidatePasswordMutation();

  const [
    activateAccount,
    { isSuccess: isActivateSuccess, isLoading: isActivateLoading },
  ] = useActivateAccountMutation();

  const isSuccess = isValidateSuccess && isActivateSuccess;
  const isLoading = isValidateLoading && isActivateLoading;

  const fieldValidationErrors = useAppSelector(selectApiFieldsValidationErrors);
  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  const [formData, setFormData] = useState<SetPasswordFormData>();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<SetPasswordFormData>({
    mode: 'all',
  });

  const onSubmit = useCallback(
    async (data: SetPasswordFormData) => {
      if (activationUrlParams) {
        setFormData(data);

        validatePassword({
          ...data,
          ...activationUrlParams,
        });
      }
    },
    [validatePassword, activationUrlParams]
  );

  useEffect(() => {
    if (
      isValidateSuccess &&
      validateResponse &&
      formData &&
      activationUrlParams
    ) {
      activateAccount({
        ...formData,
        ...activationUrlParams,
      });
    }
  }, [
    isValidateSuccess,
    validateResponse,
    formData,
    activationUrlParams,
    activateAccount,
  ]);

  const resetApiValidationErors = useCallback(() => {
    if (!!fieldValidationErrors) {
      dispatch(clearApiErrors());
    }
  }, [dispatch, fieldValidationErrors]);

  const setPasswordForm = (
    <>
      <Typography variant="body2" align="center">
        Welcome to Tuune&#39;s clinical approval platform where you'll be able
        to view and approve patient reports. To create your account and access
        your clinic's dashboard, please complete a few quick steps.
      </Typography>
      <Typography variant="body2" align="center">
        <b>Set up your password</b>
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {requestValidationError && (
            <ErrorBox message={requestValidationError} />
          )}
          <TextField
            {...register('password', {
              onChange: () => resetApiValidationErors(),
              required: 'Password is a required field.',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long',
              },
              validate: (val: string) => {
                if (!PASSWORD_REQUIRE_NUMBERS_REGEX.test(val)) {
                  return 'Password must contain numbers';
                }
                if (!PASSWORD_REQUIRE_LETTERS_REGEX.test(val)) {
                  return 'Password must contain letters';
                }
              },
            })}
            placeholder="Password"
            type="password"
            error={!!errors.password || !!fieldValidationErrors?.newPassword}
            helperText={
              errors?.password?.message || fieldValidationErrors?.newPassword
            }
          />
          <Stack spacing={1}>
            <TextField
              {...register('confirmPassword', {
                onChange: () => resetApiValidationErors(),
                required: 'Confirm password is a required field.',
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return 'Passwords should match';
                  }
                },
              })}
              placeholder="Confirm password"
              type="password"
              error={
                !!errors.confirmPassword ||
                !!fieldValidationErrors?.reNewPassword
              }
              helperText={
                errors?.confirmPassword?.message ||
                fieldValidationErrors?.reNewPassword
              }
            />

            <Typography variant="body2" color="gray" sx={{ fontSize: '12px' }}>
              Your password needs to be unique, contain letters and numbers, and
              be at least 8 characters long. Please refrain from using words
              like &#39;password&#39;, your name, or any part of your email
              address.
            </Typography>
          </Stack>
          <Button type="submit" disabled={isLoading}>
            Continue
          </Button>
        </Stack>
      </form>
    </>
  );

  const createAccountConfirmation = (
    <>
      <Typography variant="body2" align="center">
        <b>Account creation successfully completed!</b>
      </Typography>
      <Button onClick={() => navigate(routes.login)}>Log in</Button>
    </>
  );

  return (
    <AuthLayout>
      <Typography variant="h4" align="center">
        {AUTH_FLOW_PAGES_TITLE}
      </Typography>
      {isSuccess ? createAccountConfirmation : setPasswordForm}
    </AuthLayout>
  );
};
