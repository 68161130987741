export const colors = {
  white: {
    50: '#808080',
    100: '#8e8e8e',
    200: '#9c9c9c',
    300: '#aaaaaa',
    400: '#b8b8b8',
    500: '#c8c8c8',
    600: '#d6d6d6',
    700: '#e4e4e4',
    800: '#f2f2f2',
    900: '#ffffff',
  },
  black: {
    50: '#7f7f7f',
    100: '#707070',
    200: '#626262',
    300: '#545454',
    400: '#464646',
    500: '#383838',
    600: '#2a2a2a',
    700: '#1c1c1c',
    800: '#0e0e0e',
    900: '#000000',
  },
  purple: {
    50: '#F0EBFC',
    100: '#D9CDF9',
    200: '#C0ACF5',
    300: '#A68BF0',
    400: '#9372ED',
    500: '#8059EA',
    600: '#7851E7',
    700: '#6D48E4',
    800: '#633EE1',
    900: '#502EDB',
  },
  green: {
    50: '#E1F4EF',
    100: '#B4E5D8',
    200: '#83D3BE',
    300: '#51C1A4',
    400: '#2BB491',
    500: '#06A77D',
    600: '#059F75',
    700: '#04966A',
    800: '#038C60',
    900: '#027C4D',
  },
  red: {
    50: '#FDEBEA',
    100: '#FFC3C3',
    200: '#FFB2B2',
    300: '#FF908F',
    400: '#FC7674',
    500: '#F85A59',
    600: '#E94E4D',
    700: '#E54443',
    800: '#E23B3A',
    900: '#DD2A29',
  },
  yellow: {
    50: '#FFF7E1',
    100: '#FEEAB4',
    200: '#FEDD82',
    300: '#FDCF4F',
    400: '#FCC42A',
    500: '#FCBA04',
    600: '#FCB303',
    700: '#FBAB03',
    800: '#FBA302',
    900: '#FA9401',
  },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
};

export const shadow = {
  primary: `2px 2px 8px ${colors.grey[400]}`,
  secondary: `1px 1px 15px ${colors.purple[100]}`,
};
