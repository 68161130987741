import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateAppointmentState } from './types';
import { RootState } from '../store';

const initialDashboardState: CreateAppointmentState = {
  userId: null,
  patientId: null,
};

export const createAppointmentSlice = createSlice({
  name: 'createAppointment',
  initialState: initialDashboardState,
  reducers: {
    setAppointmentUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
    setAppointmentPatientId: (state, action: PayloadAction<string | null>) => {
      state.patientId = action.payload;
    },
  },
});

export const { setAppointmentUserId, setAppointmentPatientId } =
  createAppointmentSlice.actions;

export const selectAppointmentUserId = (state: RootState) => {
  return state.createAppointment.userId;
};

export const selectAppointmentPatientId = (state: RootState) => {
  return state.createAppointment.patientId;
};

export const createAppointmentReducer = createAppointmentSlice.reducer;
