import { GlobalStylesProps } from '@mui/material';
import { fontFamily } from './typography';

export const globalStyles: GlobalStylesProps['styles'] = {
  html: {
    height: '100%',
  },
  body: {
    padding: 0,
    margin: 0,
    height: '100%',
    fontFamily: fontFamily,
  },
  '#root': {
    height: '100%',
  },
};
