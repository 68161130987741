import {
  configureStore,
  ThunkAction,
  Action,
  Reducer,
  combineReducers,
} from '@reduxjs/toolkit';
import { api } from './api';
import { authReducer } from './auth/slice';
import { dashboardReducer } from './dashboard/slice';
import { notificationsReducer } from './notifications/slice';
import { accountSetupReducer } from './accountSetup/slice';
import { createAppointmentReducer } from './createAppointment/slice';

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  notifications: notificationsReducer,
  accountSetup: accountSetupReducer,
  createAppointment: createAppointmentReducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === 'auth/logout') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
