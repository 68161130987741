import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { ReportProblem } from '@mui/icons-material';
import { ReportStatus } from 'redux/dashboard/types';

interface ApproveDialogProps {
  open: boolean;
  isLoading: boolean;
  // should include name + (id)
  patient: string;
  defaultStatus?: ReportStatus;
  handleClose: () => void;
  handleSubmit: (status: ReportStatus) => void;
}
export const ApproveDialog: React.FC<ApproveDialogProps> = ({
  open,
  isLoading,
  patient,
  handleClose,
  defaultStatus = 'pending',
  handleSubmit,
}) => {
  const [status, setStatus] = useState(defaultStatus);

  const handleUpdate = () => {
    // only if status value changed(e.g. pending -> rejected, rejected -> approved) submit new status
    // skip submit if status didn't change (rejected -> rejected)
    if (status !== defaultStatus) {
      handleSubmit(status);
    } else {
      // close modal only if status didn't changed
      // if it changed the dashboard will be responsible for closing popup(success) or remain it open(errors)
      handleClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle sx={{ color: 'purple.main' }}>
        Update report status for {patient}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              borderRadius: '4px',
              padding: '18px 20px',
              boxShadow: 4,
              bgcolor: 'yellow.light',
            }}
          >
            <Typography variant="body1">
              Approved means the patient will be able to see the report.
            </Typography>
            <ReportProblem />
          </Stack>
          <RadioGroup
            defaultValue={defaultStatus}
            onChange={(e) => setStatus(e.target.value as ReportStatus)}
            name="radio-buttons-group"
            sx={{ pl: 3 }}
          >
            {['pending', 'approved', 'rejected'].map((action) => (
              <FormControlLabel
                disabled={isLoading}
                key={action}
                value={action}
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    {action.charAt(0).toUpperCase() + action.slice(1)}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
          <Stack direction="row" spacing="10px">
            <Button
              variant="outlined"
              color="black"
              fullWidth
              disabled={isLoading}
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              fullWidth
              onClick={handleUpdate}
              disabled={isLoading}
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : undefined
              }
            >
              update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
