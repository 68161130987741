import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LinearProgress, Link, Stack, Typography } from '@mui/material';
import { AuthLayout } from 'components/authLayout';
import {
  useResendActivationMutation,
  useValidateActivationLinkMutation,
} from 'redux/accountSetup/api';
import { setActivationUrlParams } from 'redux/accountSetup/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  displayErrorPopup,
  displayInfoPopup,
  selectApiFieldsValidationErrors,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';
import { createAccountSubroutes, routes } from 'routes';
import { AUTH_FLOW_PAGES_TITLE } from './constants';

const RESEND_ACTIVATION_SUCCESS_MESSAGE =
  'We sent a new activation link to your email.';

export const SetupAccount: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { uid, token } = useParams<{ uid: string; token: string }>();

  const [
    validateActivationLink,
    { isSuccess: isValidateActivationLinkSuccess, data },
  ] = useValidateActivationLinkMutation();

  const [
    resendActivation,
    {
      isSuccess: isResendActivationSuccess,
      isLoading: isResendActivationLoading,
      isUninitialized: isResendActivationUnitialized,
    },
  ] = useResendActivationMutation();

  const [isInvalidActivationLink, setIsInvalidActivationLink] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (uid && token) {
      validateActivationLink({ uid, token });
    }
  }, [validateActivationLink, uid, token]);

  useEffect(() => {
    if (isValidateActivationLinkSuccess && data?.valid) {
      dispatch(setActivationUrlParams({ uid, token }));
      navigate(`${routes.setupAccount}/${createAccountSubroutes.setPassword}`);
    } else {
      setIsInvalidActivationLink(true);
    }
  }, [
    dispatch,
    navigate,
    isValidateActivationLinkSuccess,
    data?.valid,
    uid,
    token,
  ]);

  const resendActivationLink = useCallback(() => {
    if (!isResendActivationLoading && uid) {
      resendActivation({ uid });
    }
  }, [resendActivation, isResendActivationLoading, uid]);

  useEffect(() => {
    if (isResendActivationSuccess) {
      dispatch(displayInfoPopup(RESEND_ACTIVATION_SUCCESS_MESSAGE));
    }
  }, [isResendActivationSuccess, dispatch]);

  const fieldValidationErrors = useAppSelector(selectApiFieldsValidationErrors);
  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  // Check for request activation errors or API field validation errors
  // for the resendActivation() API call
  const hasResendActivationAPiErrors =
    !isResendActivationUnitialized &&
    (requestValidationError ||
      !!fieldValidationErrors?.uid ||
      !!fieldValidationErrors?.token);

  useEffect(() => {
    // Display the popup error only for the resendActivation API calls
    if (hasResendActivationAPiErrors) {
      dispatch(
        displayErrorPopup(
          requestValidationError ||
            fieldValidationErrors?.uid ||
            fieldValidationErrors?.token
        )
      );
    }
  }, [
    dispatch,
    fieldValidationErrors?.uid,
    fieldValidationErrors?.token,
    requestValidationError,
    hasResendActivationAPiErrors,
  ]);

  return isInvalidActivationLink ? (
    <AuthLayout>
      <Typography variant="h4" align="center">
        {AUTH_FLOW_PAGES_TITLE}
      </Typography>
      <Stack
        direction="row"
        columnGap="10px"
        marginTop={24}
        padding="8px 10px"
        sx={{
          bgcolor: 'yellow.100',
        }}
      >
        <Typography variant="body2" align="center">
          This link has expired.
        </Typography>
        <Link
          variant="body2"
          color="purple.900"
          sx={{ cursor: 'pointer' }}
          onClick={resendActivationLink}
        >
          Send a new activation link.
        </Link>
      </Stack>
    </AuthLayout>
  ) : (
    <LinearProgress color="purple" />
  );
};
