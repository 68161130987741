import React, { useCallback } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthLayout } from 'components/authLayout';
import { AUTH_FLOW_PAGES_TITLE } from 'features/auth/constants';

export type ConfirmCodeFormData = {
  verificationCode: string;
};

export type ConfirmCodeProps = {
  onSubmitCallback: (data: ConfirmCodeFormData) => void;
  onBackClickCallback: () => void;
  isLoading?: boolean;
  lastPhoneDigits: string;
};

export const ConfirmCode: React.FC<ConfirmCodeProps> = ({
  onSubmitCallback,
  onBackClickCallback,
  lastPhoneDigits,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmCodeFormData>({
    mode: 'all',
  });

  const onSubmit = useCallback(
    (data: ConfirmCodeFormData) => {
      onSubmitCallback(data);
    },
    [onSubmitCallback]
  );

  return (
    <AuthLayout>
      <Typography variant="h4" align="center">
        {AUTH_FLOW_PAGES_TITLE}
      </Typography>

      <Typography variant="body2" align="center">
        Please input the verification code we sent via SMS to phone number
        ending in *** **** {lastPhoneDigits}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextField
            {...register('verificationCode', {
              required: 'Verification code is a required field.',
            })}
            placeholder="Verification code"
            type="text"
            error={!!errors.verificationCode}
            helperText={errors?.verificationCode?.message}
          />

          <Button type="submit" disabled={isLoading}>
            Continue
          </Button>
          <Button variant="text" color="black" onClick={onBackClickCallback}>
            Back
          </Button>
        </Stack>
      </form>
    </AuthLayout>
  );
};
