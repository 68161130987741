import { DEFAULT_COUNTRY_OBJ, phoneAsYouType } from './countries';

export const validatePhoneNumber = (
  phoneNumber: string,
): boolean => {
  const phoneCode = DEFAULT_COUNTRY_OBJ.phoneCode;

  phoneAsYouType.reset();
  phoneAsYouType.input(`${phoneCode}${phoneNumber}`);

  return phoneAsYouType.isValid();
};
