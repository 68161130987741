import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { routes } from 'routes';
import { ConfirmCode, ConfirmCodeFormData } from 'components/confirmPhoneCode';
import { useVerifyCodeMutation } from 'redux/auth/api';
import { useAppSelector } from 'redux/hooks';
import {
  clearApiErrors,
  displayErrorPopup,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';

type LocationState = {
  phoneLastDigits?: string;
  email: string;
  password: string;
} | null;

export const LoginConfirmCode: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  useEffect(() => {
    if (requestValidationError) {
      dispatch(displayErrorPopup(requestValidationError));
    }
  }, [dispatch, requestValidationError]);

  const [verifyCode, { isSuccess }] = useVerifyCodeMutation();

  const phoneLastDigits = useMemo(() => {
    return (location.state as LocationState)?.phoneLastDigits ?? null;
  }, [location.state]);

  useEffect(() => {
    if (phoneLastDigits === null) {
      navigate(routes.login, { replace: true });
    }
  }, [phoneLastDigits, navigate]);

  const handleGoBack = () => {
    dispatch(clearApiErrors());
    navigate(-1);
  };

  const handleSubmit = ({ verificationCode }: ConfirmCodeFormData) => {
    const locationState = location.state as LocationState;
    if (locationState) {
      verifyCode({
        code: verificationCode,
        email: locationState.email,
        password: locationState.password,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(routes.dashboard, { replace: true });
    }
  }, [isSuccess, navigate]);

  return phoneLastDigits ? (
    <ConfirmCode
      onSubmitCallback={handleSubmit}
      onBackClickCallback={handleGoBack}
      lastPhoneDigits={phoneLastDigits}
    />
  ) : null;
};
