import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';

import { LoginPage } from 'features/auth';
import { DashboardPage } from 'features/dashboard';
import { createAccountSubroutes, routes } from './routes';
import { PrivateRoute } from 'features/auth/PrivateRoute';
import { NotificationPopup } from 'features/notification/NotificationPopup';
import { PageNotFound } from './features/notFound';
import { SetupAccount } from 'features/auth/SetupAccount';
import { SetPassword } from 'features/auth//SetPassword';
import { SetTwoFactorAuth } from 'features/auth/SetPhone';
import { SignupConfirmCode } from 'features/auth/SignupConfimCode';
import { LoginConfirmCode } from 'features/auth/LoginConfirmCode';
import { CreateAppointment } from 'features/dashboard/CreateAppointment';

function App() {
  return (
    <>
      <Sentry.ErrorBoundary>
        <Router>
          <Routes>
            <Route index element={<Navigate replace to={routes.dashboard} />} />
            <Route path={routes.login} element={<LoginPage />} />
            <Route path={routes.verify} element={<LoginConfirmCode />} />
            <Route path={routes.setupAccount} element={<Outlet />}>
              <Route path=":uid/:token" element={<SetupAccount />} />
              <Route
                path={createAccountSubroutes.setPassword}
                element={<SetPassword />}
              />
              <Route
                path={createAccountSubroutes.setPhone}
                element={<SetTwoFactorAuth />}
              />
              <Route
                path={createAccountSubroutes.confirmCode}
                element={<SignupConfirmCode />}
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path={routes.dashboard} element={<DashboardPage />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path={routes.createAppointment} element={<CreateAppointment />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        <NotificationPopup />
      </Sentry.ErrorBoundary>
    </>
  );
}

export default App;
