import { Typography } from '@mui/material';

export const fontSizes: Partial<typeof Typography> = {
  h1: {
    fontWeight: 'bold',
    fontSize: '45px',
    lineHeight: 1.244,
  },
  h2: {
    fontWeight: 'bold',
    fontSize: '34px',
    lineHeight: 1.529,
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: 1.416,
  },
  h4: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: 1.6,
  },
  h5: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: 1.5,
    letterSpacing: 0.2,
  },
  h6: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: 1.428,
  },
  body1: {
    fontSize: '16px',
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '14px',
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: '20px',
    lineHeight: 1,
    letterSpacing: 0.15,
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '12px',
    lineHeight: 1.33,
    letterSpacing: 0.15,
    fontWeight: 500,
  },
  caption: {
    fontSize: '12px',
    lineHeight: 1.5,
  },
  overline: {
    fontSize: '12px',
    lineHeight: 1.5,
    letterSpacing: 0.3,
  },
  button: {
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: '0.1rem',
    fontWeight: 'normal',
  },
};

export const fontFamily = [
  'Poppins',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');
