import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Link,
  styled,
  Modal,
  Paper,
  Stack,
} from '@mui/material';
import { Close, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const StyledDocument = styled(Document)({
  margin: 'auto',
});

type PdfModalProps = {
  pdfUrl?: string;
  open: boolean;
  pdfName: string;
  handleClose: () => void;
};
export const PdfModal: React.FC<PdfModalProps> = ({
  pdfUrl,
  open,
  handleClose,
  pdfName,
}) => {
  const [pdfPages, setPdfPages] = useState(0);
  const docRef = useRef<null | HTMLDivElement>(null);
  const [scale, setScale] = useState(2.1);

  const zoomOut = () => {
    setScale((scale) => scale - 0.1);
  };

  const zoomIn = () => {
    setScale((scale) => scale + 0.1);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="patient-report-pdf"
    >
      <Box
        sx={{
          py: 1,
          px: 3,
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Paper
          sx={{
            p: 2,
            height: '100%',
            maxWidth: '1440px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            margin: 'auto',
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <IconButton
                color="inherit"
                onClick={zoomOut}
                disabled={scale < 0.5}
              >
                <ZoomOut />
              </IconButton>
              <IconButton color="inherit" onClick={zoomIn} disabled={scale > 2}>
                <ZoomIn />
              </IconButton>
            </Stack>
            <Button variant="text" onClick={handleClose}>
              <Close />
            </Button>
          </Stack>
          <div ref={docRef} style={{ height: '100%', overflow: 'hidden' }}>
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                display: 'flex',
              }}
            >
              {pdfUrl && (
                <StyledDocument
                  file={pdfUrl}
                  onLoadSuccess={(data) => setPdfPages(data._pdfInfo.numPages)}
                >
                  {Array(pdfPages)
                    .fill(1)
                    .map((_, idx) => (
                      <React.Fragment key={idx}>
                        <Box sx={{ border: '1px solid grey', mb: 2 }}>
                          <Page
                            scale={scale}
                            pageNumber={idx + 1}
                            height={docRef.current?.clientHeight}
                          />
                        </Box>
                      </React.Fragment>
                    ))}
                </StyledDocument>
              )}
            </Box>
          </div>
          <Box display="flex" justifyContent="center">
            <Link
              href={pdfUrl}
              download={pdfName}
              component={Button}
              sx={{ color: 'white.main' }}
            >
              download report
            </Link>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};
