import { api } from 'redux/api';
import { setupAccountApiUrls } from './constants';
import { ActivationUrlParams } from './types';
import { SetPasswordFormData } from 'features/auth/SetPassword';
import { SetPhoneFormData } from 'features/auth/SetPhone';
import { ConfirmCodeFormData } from 'components/confirmPhoneCode';

const setupAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateActivationLink: builder.mutation<
      { valid: boolean },
      ActivationUrlParams
    >({
      query: (requestBody) => ({
        url: setupAccountApiUrls.validateActivationLink,
        method: 'POST',
        body: requestBody,
      }),
    }),

    validatePassword: builder.mutation<
      { valid: boolean },
      SetPasswordFormData & ActivationUrlParams
    >({
      query: ({ password, confirmPassword, uid, token }) => ({
        url: setupAccountApiUrls.validatePassword,
        method: 'POST',
        body: {
          new_password: password,
          re_new_password: confirmPassword,
          uid,
          token,
        },
      }),
    }),

    confirmPhoneNumber: builder.mutation<
      void,
      SetPhoneFormData & ActivationUrlParams
    >({
      query: ({ phoneNumber, uid, token }) => ({
        url: setupAccountApiUrls.confirmPhoneNumber,
        method: 'POST',
        body: {
          phone_number: phoneNumber,
          uid,
          token,
        },
      }),
    }),

    activateAccount: builder.mutation<
      void,
      SetPasswordFormData &
        SetPhoneFormData &
        ConfirmCodeFormData &
        ActivationUrlParams
    >({
      query: ({ password, phoneNumber, verificationCode, uid, token }) => ({
        url: setupAccountApiUrls.activateAccount,
        method: 'POST',
        body: {
          new_password: password,
          re_new_password: password,
          phone_number: phoneNumber,
          verification_code: verificationCode,
          uid,
          token,
        },
      }),
    }),

    resendActivation: builder.mutation<void, { uid: string }>({
      query: ({ uid }) => ({
        url: setupAccountApiUrls.resendActivation,
        method: 'POST',
        body: {
          uid,
        },
      }),
    }),
  }),
});

export const {
  useValidateActivationLinkMutation,
  useValidatePasswordMutation,
  useConfirmPhoneNumberMutation,
  useActivateAccountMutation,
  useResendActivationMutation,
} = setupAccountApi;
