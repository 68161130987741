import React from 'react';
import { Chip } from '@mui/material';
import { ReportStatus } from 'redux/dashboard/types';

export const STATUS_CHIP_MAP: Record<
  ReportStatus | 'default',
  [label: string, color: string]
> = {
  default: ['HA pending', 'grey'],
  pending: ['awaiting approval', 'yellow'],
  approved: ['approved', 'green'],
  rejected: ['rejected', 'red'],
};

interface StatusChipProps {
  status: ReportStatus | null;
  handleClick: () => void;
}
export const StatusChip: React.FC<StatusChipProps> = ({
  status,
  handleClick,
}) => {
  const [label, color] = STATUS_CHIP_MAP[status ?? 'default'];

  return (
    <Chip
      label={label}
      disabled={status === null}
      sx={{
        fontSize: '12px',
        color: color + '.900',
        bgcolor: color + '.50',
        borderColor: color + '.100',
        '&&:hover': {
          bgcolor: color + '.100',
        },
      }}
      variant="outlined"
      onClick={handleClick}
    />
  );
};
