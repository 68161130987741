import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { Patient } from 'redux/dashboard/types';
import {
  ITEMS_LIMIT_PER_PAGE,
  useDownloadReportMutation,
} from 'redux/dashboard/api';
import { selectTotalCount } from 'redux/dashboard/slice';
import { PatientCoreData } from './types';
import {
  APPOINTMENT_TABLE_CELLS,
  PATIENT_TABLE_CELLS,
  REPORT_TABLE_CELLS,
} from './constants';
import { PdfModal } from './PdfModal';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface PatientsTableProps {
  patients: Patient[] | null;
  handleOpenApproveDialog: (patientData: PatientCoreData) => void;
  page: number;
  setPage: (page: number) => void;
  isFetching: boolean;
}
export const PatientsTable: React.FC<PatientsTableProps> = ({
  patients,
  handleOpenApproveDialog,
  page,
  setPage,
  isFetching,
}) => {
  const totalCount = useAppSelector(selectTotalCount);

  const [downloadReportPdf, { data: pdfUrl }] = useDownloadReportMutation();
  const [reportPdfOpen, setPdfReportOpen] = useState(false);
  const [pdfName, setPdfName] = useState('report');

  const allRecordsLoaded = useMemo<boolean>(() => {
    if (totalCount === null) {
      return false;
    }
    return !(page * ITEMS_LIMIT_PER_PAGE < totalCount);
  }, [page, totalCount]);

  const handleChipClick = ({ id: patientId, fullName, report }: Patient) => {
    // if report == null, chip is disabled and handleChipClick is not called
    handleOpenApproveDialog({
      patientId,
      reportId: report!.id,
      fullName,
      approvalStatus: report!.approvalStatus,
    });
  };

  const handleClickReportPdf = ({
    id: patientId,
    pdfReportUrl,
    appointment,
  }: Patient) => {
    const url = pdfReportUrl!.replace('/api/v1', '');
    const reportName =
      [
        appointment.date,
        appointment.clinic,
        appointment.clinician,
        'ID ' + patientId,
      ].join('_') + '.pdf';
    downloadReportPdf({ url });
    setPdfReportOpen(true);
    setPdfName(reportName);
  };

  const columns: GridColDef[] = [
    ...PATIENT_TABLE_CELLS,
    ...APPOINTMENT_TABLE_CELLS,
    ...REPORT_TABLE_CELLS(handleChipClick, handleClickReportPdf),
  ];

  const handlePageChange = (newPage: number) => {
    if (!allRecordsLoaded) {
      setPage(newPage + 1);
    }
  };

  return (
    <>
      <PdfModal
        pdfUrl={pdfUrl}
        open={reportPdfOpen}
        handleClose={() => {
          setPdfReportOpen(false);
        }}
        pdfName={pdfName}
      />
      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .row-even': {
            bgcolor: 'purple.50',
            '&:hover': {
              bgcolor: 'purple.50',
            },
          },
          '& .row-odd': {
            bgcolor: 'white.main',
            '&:hover': {
              bgcolor: 'white.main',
            },
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
      >
        <DataGrid
          rows={patients ?? []}
          rowCount={totalCount}
          columns={columns}
          loading={isFetching}
          pageSize={ITEMS_LIMIT_PER_PAGE}
          rowsPerPageOptions={[5]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
          }
          disableSelectionOnClick
          pagination
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page - 1}
        />
      </Box>
    </>
  );
};
