import React from 'react';
import { CountryObj } from 'utils/countries';
import { TextField, TextFieldProps } from '@mui/material';
import { PhoneNumberFormat } from 'components/phoneNumberFormat';

export type PhoneTextFieldProps = {
  countryObj: CountryObj;
} & TextFieldProps;

export const PhoneTextField: React.FC<PhoneTextFieldProps> = ({
  countryObj,
  ...otherProps
}) => {
  return (
    <TextField
      name="phone"
      autoComplete="tel"
      placeholder="Phone number"
      {...otherProps}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: PhoneNumberFormat as any,
        inputProps: {
          countryObj,
        },
      }}
    />
  );
};
