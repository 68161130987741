import React, { ChangeEvent } from 'react';
import NumberFormat from 'react-number-format';
import { CountryObj } from 'utils/countries';

export interface PhoneNumberFormatProps {
  onChange: (event: ChangeEvent<{ value: string }>) => void;
  name: string;
  countryObj: CountryObj;
  inputRef: React.Ref<HTMLInputElement>;
}

export const PhoneNumberFormat = React.forwardRef<
  NumberFormat<string>,
  PhoneNumberFormatProps
>((props, refs) => {
  //
  // We need to destructure inputRef to avoid it being passed as a prop
  // to NumberFormat underlying DOM element, causing a React console error
  //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, name, countryObj, inputRef, ...other } = props;

  // The country phone code, followed by the national phone format
  // US example: +1 (###) ###-####
  const phoneNumberFormat = `${
    countryObj?.phoneCode
  } ${countryObj?.phoneNumberExample
    ?.format('NATIONAL')
    .replace(/[0-9]/g, '#')}`;

  return (
    <NumberFormat
      {...other}
      getInputRef={refs}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        } as ChangeEvent<{ name: string; value: string }>);
      }}
      isNumericString
      format={phoneNumberFormat}
    />
  );
});
