import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Link,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectClinic } from 'redux/dashboard/slice';
import { EMAIL_VALIDATION_REGEX } from 'features/auth/constants';
import { ErrorBox } from 'components/errorBox';
import {
  clearApiErrors,
  displayErrorPopup,
  displayStickyInfoPopup,
  selectApiFieldsValidationErrors,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';
import { Controller, useForm } from 'react-hook-form';
import { Logo } from 'components/logo';
import { CREATE_APPOINTMENT_PAGE_TITLE } from './constants';
import { US_STATES } from 'utils/usStates';
import {
  useGetAccountDataQuery,
  useGetCliniciansQuery,
} from 'redux/dashboard/api';
import { Clinician } from 'redux/dashboard/types';
import { PhoneTextField } from 'components/phoneTextField';
import { validatePhoneNumber } from 'utils/validation';
import { DEFAULT_COUNTRY_OBJ } from 'utils/countries';
import {
  useCreateAppointmentMutation,
  useCreatePatientMutation,
  useCreateUserMutation,
} from 'redux/createAppointment/api';
import {
  selectAppointmentPatientId,
  selectAppointmentUserId,
  setAppointmentPatientId,
  setAppointmentUserId,
} from 'redux/createAppointment/slice';
import { useNavigate } from 'react-router';
import { routes } from 'routes';
import { DEFAULT_ERROR_MESSAGE } from 'redux/notifications/constants';

export type CreateAppointmentFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  country: string;
  state: string;

  clinicianId: string;
  appointmentDate: Date | null;

  acceptMarketingAndResearch: boolean;
};

export const CreateAppointment: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clinic = useAppSelector(selectClinic);
  const appointmentUserId = useAppSelector(selectAppointmentUserId);
  const appointmentPatientId = useAppSelector(selectAppointmentPatientId);

  const { isLoading: isAccountDataLoading, isError: isAccountDataError } =
    useGetAccountDataQuery();

  // wait for successful call to /me before fetching patients
  const delayCliniciansRequest =
    isAccountDataLoading || isAccountDataError || clinic == null;

  const { isLoading: isGetCliniciansLoading } = useGetCliniciansQuery(
    { clinicSlug: clinic?.slug ?? '' },
    { skip: delayCliniciansRequest }
  );

  const [
    createUser,
    { isLoading: isCreateUserLoading, isSuccess: isCreateUserSuccess },
  ] = useCreateUserMutation();

  const [
    createPatient,
    { isLoading: isCreatePatientLoading, isSuccess: isCreatePatientSuccess },
  ] = useCreatePatientMutation();

  const [
    createAppointment,
    {
      data: createAppointmentResponse,
      isLoading: isCreateAppointmentLoading,
      isSuccess: isCreateAppointmentSuccess,
    },
  ] = useCreateAppointmentMutation();

  const fieldValidationErrors = useAppSelector(selectApiFieldsValidationErrors);
  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  const [formData, setFormData] = useState<CreateAppointmentFormData>();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAppointmentFormData>({
    mode: 'all',
  });

  const onSubmit = useCallback(
    (data: CreateAppointmentFormData) => {
      // Reset the previous state first
      dispatch(setAppointmentUserId(null));
      dispatch(setAppointmentPatientId(null));

      setFormData(data);
      // Step 1 - Create user
      createUser({
        ...data,
        country: DEFAULT_COUNTRY_OBJ.name,
        phoneNumber: `${DEFAULT_COUNTRY_OBJ.phoneCode} ${data.phoneNumber}`,
      });
    },
    [dispatch, createUser]
  );

  useEffect(() => {
    if (isCreateUserSuccess && appointmentUserId && clinic) {
      // Step 2 - Create patient
      createPatient({
        userId: appointmentUserId,
        clinicSlug: clinic.slug,
      });
    }
  }, [isCreateUserSuccess, appointmentUserId, createPatient, clinic]);

  useEffect(() => {
    if (
      isCreatePatientSuccess &&
      appointmentPatientId &&
      formData &&
      formData.appointmentDate
    ) {
      // Format date using yyyy-mm-dd format
      const appointmentDateStr = formData.appointmentDate
        .toISOString()
        .split('T')[0];
      // Step 3 - Create appointment
      createAppointment({
        patientId: appointmentPatientId,
        clinicianId: formData.clinicianId,
        date: appointmentDateStr,
      });
    }
  }, [
    isCreatePatientSuccess,
    appointmentPatientId,
    formData,
    createAppointment,
  ]);

  useEffect(() => {
    if (isCreateAppointmentSuccess && formData && formData.appointmentDate) {
      if (createAppointmentResponse?.error?.code === 0) {
        // Format date using yyyy-mm-dd format
        const appointmentDateStr = formData.appointmentDate
          .toISOString()
          .split('T')[0];

        dispatch(
          displayStickyInfoPopup(
            `Successfully created an appointment  for ${formData.firstName} ${formData.lastName} on ${appointmentDateStr}. Patient will appear in the table once they register.`
          )
        );
        navigate(routes.dashboard);
      } else {
        dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
      }
    }
  }, [
    isCreateAppointmentSuccess,
    createAppointmentResponse?.error?.code,
    formData,
    dispatch,
    navigate,
  ]);

  const resetApiValidationErors = useCallback(() => {
    if (!!fieldValidationErrors) {
      dispatch(clearApiErrors());
    }
  }, [dispatch, fieldValidationErrors]);

  const renderStateSelectValue = (selected: unknown) => {
    if (!selected) {
      return (
        <Typography variant="body1" color="textSecondary">
          Select State
        </Typography>
      );
    } else {
      return <Typography variant="body1">{selected as string}</Typography>;
    }
  };

  const renderClinicianSelectValue = (selected: unknown) => {
    if (!selected) {
      return (
        <Typography variant="body1" color="textSecondary">
          Select patient's doctor
        </Typography>
      );
    } else {
      const renderedClinician = clinic?.clinicians?.find(
        (clinician: Clinician) => clinician.id === selected
      );
      return (
        <Typography variant="body1">{renderedClinician?.fullName}</Typography>
      );
    }
  };

  if (isGetCliniciansLoading) {
    return <></>;
  }

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: '80px',
      }}
    >
      <Stack spacing={3} sx={{ width: '630px' }}>
        <Box display="flex" justifyContent="center">
          <Logo />
        </Box>
        <Divider />
        <Typography variant="h4" align="center">
          {CREATE_APPOINTMENT_PAGE_TITLE}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {requestValidationError && (
              <ErrorBox message={requestValidationError} />
            )}
            <Stack spacing={1}>
              <TextField
                {...register('firstName', {
                  onChange: () => resetApiValidationErors(),
                  required: 'First name is a required field.',
                })}
                placeholder="Patient first name"
                error={!!errors.firstName || !!fieldValidationErrors?.firstName}
                helperText={
                  errors?.firstName?.message ||
                  fieldValidationErrors?.firstName ||
                  "Please enter the patient's first name"
                }
              />
            </Stack>

            <Stack spacing={1}>
              <TextField
                {...register('lastName', {
                  onChange: () => resetApiValidationErors(),
                  required: 'Last name is a required field.',
                })}
                placeholder="Patient last name"
                error={!!errors.lastName || !!fieldValidationErrors?.lastName}
                helperText={
                  errors?.lastName?.message ||
                  fieldValidationErrors?.lastName ||
                  "Please enter the patient's last name"
                }
              />
            </Stack>

            <TextField
              {...register('email', {
                onChange: () => resetApiValidationErors(),
                required: 'Email is a required field.',
                pattern: {
                  value: EMAIL_VALIDATION_REGEX,
                  message: 'Email is not valid.',
                },
              })}
              placeholder="Patient email"
              error={!!errors.email || !!fieldValidationErrors?.email}
              helperText={
                errors?.email?.message || fieldValidationErrors?.email
              }
            />

            <PhoneTextField
              {...register('phoneNumber', {
                required: 'Phone number is a required field.',
                validate: (val: string) => {
                  if (!validatePhoneNumber(val)) {
                    return 'Phone number format incorrect';
                  }
                },
              })}
              countryObj={DEFAULT_COUNTRY_OBJ}
              placeholder="Patient phone number"
              error={
                !!errors.phoneNumber || !!fieldValidationErrors?.phoneNumber
              }
              helperText={
                errors?.phoneNumber?.message ||
                fieldValidationErrors?.phoneNumber
              }
            />

            <FormControl
              error={!!errors.state || !!fieldValidationErrors?.state}
            >
              <Select
                {...register('state', {
                  onChange: () => resetApiValidationErors(),
                  required: 'State is a required field.',
                })}
                fullWidth
                variant="outlined"
                displayEmpty
                renderValue={renderStateSelectValue}
                error={!!errors.state}
              >
                {US_STATES?.map(({ abbreviation }) => (
                  <MenuItem key={abbreviation} value={abbreviation}>
                    {abbreviation}
                  </MenuItem>
                ))}
              </Select>
              {errors.state?.message && (
                <FormHelperText>{errors.state?.message}</FormHelperText>
              )}
            </FormControl>

            <Controller
              name="appointmentDate"
              control={control}
              rules={{
                required: 'Appointment date is a required field.',
              }}
              defaultValue={null}
              render={({ field: { onChange, ...restField } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Select patient appointment date"
                    inputFormat="MM/dd/yyyy"
                    onChange={(event) => {
                      onChange(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !!errors.appointmentDate ||
                          !!fieldValidationErrors?.appointmentDate
                        }
                        helperText={
                          errors?.appointmentDate?.message ||
                          fieldValidationErrors?.appointmentDate
                        }
                      />
                    )}
                    disablePast
                    {...restField}
                  />
                </LocalizationProvider>
              )}
            />

            <FormControl
              error={
                !!errors.clinicianId || !!fieldValidationErrors?.clinicianId
              }
            >
              <Select
                {...register('clinicianId', {
                  onChange: () => resetApiValidationErors(),
                  required: `Patient's doctor is a required field.`,
                })}
                fullWidth
                variant="outlined"
                displayEmpty
                renderValue={renderClinicianSelectValue}
                error={!!errors.clinicianId}
              >
                {clinic?.clinicians?.map(({ id, fullName }: Clinician) => (
                  <MenuItem key={id} value={id}>
                    {fullName}
                  </MenuItem>
                ))}
              </Select>
              {errors.clinicianId?.message && (
                <FormHelperText>{errors.clinicianId?.message}</FormHelperText>
              )}
            </FormControl>

            <FormGroup>
              <FormControlLabel
                {...register('acceptMarketingAndResearch', {
                  onChange: () => resetApiValidationErors(),
                })}
                control={<Checkbox />}
                label="Please confirm that patient is happy to receive email and SMS notifications about
                completing the survey from Tuune. They will receive a double opt in for reminders."
              />
            </FormGroup>

            <Typography variant="caption" color="textSecondary">
              By entering the patient&#39;s phone number and submitting this
              form, you confirm that they&#39;ve consented to receive marketing
              messages from Tune at the number provided, including any messages
              sent by the autodialer. Consent is not a condition of any
              purchase. Message and data rates may apply. Message frequency
              varies. Patients can unsubscribe at any time by replying STOP or
              using the unsubscribe link (where available) in one of our
              messages. View our{' '}
              <Link
                href="https://www.tuune.com/privacy-policy-app"
                variant="caption"
                color="textSecondary"
                target="_blank"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any): void => e.stopPropagation()}
              >
                Privacy policy
              </Link>{' '}
              and{' '}
              <Link
                href="https://www.tuune.com/terms-and-conditions"
                variant="caption"
                color="textSecondary"
                target="_blank"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any): void => e.stopPropagation()}
              >
                Terms of Service
              </Link>
            </Typography>

            <LoadingButton
              type="submit"
              loading={
                isCreateUserLoading ||
                isCreatePatientLoading ||
                isCreateAppointmentLoading
              }
              variant="contained"
            >
              Create appointment
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
};
