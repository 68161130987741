import React, { useCallback, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { createAccountSubroutes, routes } from 'routes';
import { AUTH_FLOW_PAGES_TITLE } from './constants';
import { AuthLayout } from 'components/authLayout';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAccountSetupPassword,
  selectActivationUrlParams,
  setAccountSetupPhone,
} from 'redux/accountSetup/slice';
import {
  displayErrorPopup,
  selectApiFieldsValidationErrors,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';
import { DEFAULT_ERROR_MESSAGE } from 'redux/notifications/constants';
import { useConfirmPhoneNumberMutation } from 'redux/accountSetup/api';
import { ErrorBox } from 'components/errorBox';
import { PhoneTextField } from 'components/phoneTextField';
import { DEFAULT_COUNTRY_OBJ } from 'utils/countries';
import { validatePhoneNumber } from 'utils/validation';

export type SetPhoneFormData = {
  phoneNumber: string;
};

export const SetTwoFactorAuth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activationUrlParams = useAppSelector(selectActivationUrlParams);
  const password = useAppSelector(selectAccountSetupPassword);

  useEffect(() => {
    if (!activationUrlParams || !password) {
      dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
      navigate(routes.login);
    }
  }, [activationUrlParams, password, dispatch, navigate]);

  const [confirmPhoneNumber, { isSuccess, isLoading, originalArgs }] =
    useConfirmPhoneNumberMutation();

  const fieldValidationErrors = useAppSelector(selectApiFieldsValidationErrors);
  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SetPhoneFormData>({
    mode: 'all',
  });

  const onSubmit = useCallback(
    async (data: SetPhoneFormData) => {
      if (activationUrlParams) {
        confirmPhoneNumber({
          phoneNumber: `${DEFAULT_COUNTRY_OBJ.phoneCode} ${data.phoneNumber}`,
          ...activationUrlParams,
        });
      }
    },
    [confirmPhoneNumber, activationUrlParams]
  );

  useEffect(() => {
    if (isSuccess && !!originalArgs?.phoneNumber) {
      dispatch(setAccountSetupPhone(originalArgs?.phoneNumber));
      navigate(`${routes.setupAccount}/${createAccountSubroutes.confirmCode}`);
    }
  }, [isSuccess, dispatch, navigate, originalArgs?.phoneNumber]);

  return (
    <AuthLayout>
      <Typography variant="h4" align="center">
        {AUTH_FLOW_PAGES_TITLE}
      </Typography>
      <Typography variant="body2" align="center">
        <b>Set up your two-factor authentication</b>
      </Typography>
      <Typography variant="body2" align="center">
        We require two-factor authentication when creating your account and when
        you sign-in. This added layer of protection keeps your patient data
        safe. You will need to do this each time you sign in and will be
        automatically logged out after 10-minutes of inactivity for added
        security.
      </Typography>
      <Typography variant="body2" align="center">
        Please input your mobile number to receive a verification code via SMS.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {requestValidationError && (
            <ErrorBox message={requestValidationError} />
          )}
          <Stack spacing={1}>
            <PhoneTextField
              {...register('phoneNumber', {
                required: 'Phone number is a required field.',
                validate: (val: string) => {
                  if (!validatePhoneNumber(val)) {
                    return 'Phone number format incorrect';
                  }
                },
              })}
              countryObj={DEFAULT_COUNTRY_OBJ}
              error={
                !!errors.phoneNumber || !!fieldValidationErrors?.phoneNumber
              }
              helperText={
                errors?.phoneNumber?.message ||
                fieldValidationErrors?.phoneNumber
              }
            />

            <Typography
              variant="caption"
              color="gray"
              sx={{ fontSize: '12px' }}
            >
              Carrier SMS rates may apply.
            </Typography>
          </Stack>

          <Button type="submit" disabled={isLoading}>
            Continue
          </Button>
        </Stack>
      </form>
    </AuthLayout>
  );
};
