import React, { useCallback, useEffect, useState } from 'react';
import { Button, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  clearPopup,
  selectNotificationPopupMessage,
} from 'redux/notifications/slice';

const BACKGROUND_COLORS = {
  info: 'purple.900',
  error: 'red.900',
};

export const NotificationPopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(BACKGROUND_COLORS.info);
  const [message, setMessage] = useState<string>();
  const notificationMessage = useAppSelector(selectNotificationPopupMessage);

  useEffect(() => {
    if (notificationMessage) {
      if (notificationMessage.type === 'error') {
        setColor(BACKGROUND_COLORS.error);
      } else {
        setColor(BACKGROUND_COLORS.info);
      }
      setMessage(notificationMessage.message);
      setIsOpen(true);
    }
  }, [notificationMessage]);

  const handleDismiss = useCallback(() => {
    setIsOpen(false);
    dispatch(clearPopup());
  }, [dispatch]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={notificationMessage?.autoHide ? 4000 : null}
      onClose={handleDismiss}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <SnackbarContent
        message={<Typography variant="body2">{message}</Typography>}
        sx={{
          bgcolor: color,
        }}
        action={
          <Button variant="text" color="inherit" onClick={handleDismiss}>
            dismiss
          </Button>
        }
      />
    </Snackbar>
  );
};
