import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface ErrorBoxProps {
  message: ReactNode;
}
export const ErrorBox: React.FC<ErrorBoxProps> = ({ message }) => {
  return (
    <Box
      sx={{
        color: 'red.900',
        bgcolor: 'red.50',
        borderRadius: 1,
        padding: 2,
        fontSize: '12px',
      }}
    >
      {message}
    </Box>
  );
};
