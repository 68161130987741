import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AuthState } from './types';

const initialAuthState: AuthState = {
  isAuthenticated: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { logout, setIsAuthenticated } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => {
  return state.auth.isAuthenticated;
};

export const authReducer = authSlice.reducer;
