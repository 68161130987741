import { TableFiltersType } from 'features/dashboard/types';
import { snakeCase } from 'snake-case';

export class PatientsUrl {
  private _url = '';

  constructor(clinicSlug: string) {
    this._url = `clinics/${clinicSlug}/patients/`;
  }

  addPagination(page: number, itemsPerPage: number) {
    this._url += `?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`;
  }

  addFilters(filters: TableFiltersType) {
    (Object.keys(filters) as (keyof TableFiltersType)[]).forEach(
      (filterKey) => {
        this._url += `&${snakeCase(filterKey)}=${filters[filterKey]}`;
      }
    );
  }

  get url(): string {
    return this._url;
  }
}
