import React from 'react';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Logo } from 'components/logo';
import { useLogoutMutation } from 'redux/auth/api';

interface NavbarProps {
  clinic?: string;
  clinician?: string;
  isLoading?: boolean;
}
export const Navbar: React.FC<NavbarProps> = ({
  clinic = '',
  clinician = '',
  isLoading,
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [logout] = useLogoutMutation();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogOut = () => {
    handleCloseNavMenu();
    logout();
  };

  return (
    <AppBar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo />
          {clinic && <Typography variant="h4">&nbsp;x {clinic}</Typography>}

          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4">{clinician}</Typography>
            <IconButton
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
              onClick={handleOpenNavMenu}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem onClick={handleLogOut}>
                <Typography textAlign="center">Sign Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {isLoading && <LinearProgress color="purple" />}
    </AppBar>
  );
};
