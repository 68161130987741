import { api } from 'redux/api';
import camelcaseKeys from 'camelcase-keys';
import { PatientsUrl } from 'utils/PatientsUrl';
import { displayErrorPopup, displayInfoPopup } from 'redux/notifications/slice';
import { DEFAULT_ERROR_MESSAGE } from 'redux/notifications/constants';
import {
  Clinician,
  DownloadReportPdfRequest,
  GetCliniciansRequest,
  Patient,
  PatientListRequest,
  PatientRecord,
  PatientsListResponse,
  UpdateStatusRequest,
} from './types';
import { Account } from './types';
import {
  setAccount,
  setClinicians,
  setPatients,
  setTotalCount,
  updatePatient,
} from './slice';
import { RootState } from '../store';

// You can change the value for QA purposes
export const ITEMS_LIMIT_PER_PAGE = 100;

const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<
      { patients: Patient[]; count: number; isFirstPage: boolean },
      PatientListRequest
    >({
      query: ({
        clinicSlug,
        itemsPerPage = ITEMS_LIMIT_PER_PAGE,
        page = 1,
        filters,
      }) => {
        // base path
        const patientsUrl = new PatientsUrl(clinicSlug);
        //pagination
        patientsUrl.addPagination(page, itemsPerPage);
        // filtering
        patientsUrl.addFilters(filters);
        return {
          url: patientsUrl.url,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setPatients({
              patients: data.patients,
              rewrite: data.isFirstPage,
            })
          );

          const currentTotal = (getState() as RootState).dashboard.totalCount;
          if (currentTotal === null || data.count !== currentTotal) {
            dispatch(setTotalCount(data.count));
          }
        } catch (error) {
          dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
        }
      },
      transformResponse: (data: PatientsListResponse) => {
        return {
          isFirstPage: data.previous == null,
          count: data.count,
          patients: camelcaseKeys(data.results, {
            deep: true,
          }).map((patient) => ({
            ...patient,
            pdfReportUrl: patient.pdfReportUrl,
            fullName: `${patient.firstName} ${patient.lastName}`,
            appointment: {
              ...patient.appointment,
              clinic: patient.appointment.clinic.shortName,
              clinician: patient.appointment.clinician.fullName,
              date: patient.appointment.date,
            },
            report: patient.report
              ? {
                  ...patient.report,
                  approvalDate: patient.report.approvalDate,
                  haCompletionDate: patient.report.haCompletionDate,
                  approver: patient.report.approver?.fullName ?? null,
                  reportStatus: patient.report?.approvalStatus,
                }
              : null,
          })),
        };
      },
    }),
    getAccountData: builder.query<Account, void>({
      query: () => ({
        url: 'clinics/clinicians/me',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(camelcaseKeys(data, { deep: true })));
        } catch (error) {
          // TODO replace with setting an app error in redux
          console.log(error);
        }
      },
    }),
    updateStatus: builder.mutation<PatientRecord, UpdateStatusRequest>({
      query: ({ clinicSlug, reportId, approvalStatus }) => ({
        url: `clinics/${clinicSlug}/reports/${reportId}/status`,
        method: 'POST',
        body: { report_status: approvalStatus },
      }),
      async onQueryStarted(
        { reportId, payload: patientUpdatePayload },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(updatePatient(patientUpdatePayload));
          dispatch(
            displayInfoPopup(
              `Report status for ${patientUpdatePayload.name} is successfully changed to ${patientUpdatePayload.approvalStatus}`
            )
          );
        } catch (error) {}
      },
    }),
    downloadReport: builder.mutation<string, DownloadReportPdfRequest>({
      queryFn: async ({ url }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: url,
          responseHandler: async (response) => await response.blob(),
        });
        const pdfUrl = window.URL.createObjectURL(result.data as Blob);

        return { data: pdfUrl };
      },
    }),
    getClinicians: builder.query<Clinician[], GetCliniciansRequest>({
      query: ({ clinicSlug }) => ({
        url: `clinics/${clinicSlug}/clinicians/`,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setClinicians(
              data.map((clinician: any) =>
                camelcaseKeys(clinician, { deep: true })
              )
            )
          );
        } catch (error) {
          dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
        }
      },
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useGetAccountDataQuery,
  useGetCliniciansQuery,
  useUpdateStatusMutation,
  useDownloadReportMutation,
} = dashboardApi;
