import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { StatusChip } from './StatusChip';
import { Patient, ReportStatus } from 'redux/dashboard/types';
import { formatDate } from '../../utils/formatDate';
import { TextSnippetOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

export const PATIENT_TABLE_CELLS: GridColDef[] = [
  { field: 'id', headerName: 'Pat. ID', minWidth: 70, flex: 1 },
  { field: 'firstName', headerName: 'First Name', flex: 1 },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
];

export const APPOINTMENT_TABLE_CELLS: GridColDef[] = [
  {
    field: 'clinic',
    headerName: 'Clinic',
    flex: 1,
    valueGetter: (params) => {
      return params.row.appointment.clinic;
    },
  },
  {
    field: 'date',
    headerName: 'Apt. Date',
    flex: 1,
    valueGetter: (params) => {
      return formatDate(params.row.appointment.date) ?? '-';
    },
  },
  {
    field: 'clinician',
    headerName: 'Clinician',

    flex: 1,
    valueGetter: (params) => {
      return params.row.appointment.clinician;
    },
  },
];

export const REPORT_TABLE_CELLS = (
  handleChipClick: (patient: Patient) => void,
  handleClickReportPdf: (patient: Patient) => void
): GridColDef[] => [
  {
    field: 'haCompletionDate',
    headerName: 'HA comp. date',
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      return formatDate(params.row.report?.haCompletionDate) ?? '-';
    },
  },
  {
    field: 'approvalStatus',
    headerName: 'Report Status',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      return params.row.report?.approvalStatus;
    },
    renderCell: (params) => {
      return (
        <StatusChip
          status={params.value as ReportStatus}
          handleClick={() => handleChipClick(params.row as Patient)}
        />
      );
    },
  },
  {
    field: 'approver',
    headerName: 'Reviewed By',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      return params.row.report?.approver ?? '-';
    },
  },
  {
    field: 'approvalDate',
    headerName: 'Review Date',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      return formatDate(params.row.report?.approvalDate) ?? '-';
    },
  },
  {
    field: 'pdfReportUrl',
    headerName: 'Pdf Report',
    renderCell: (params) => {
      return params.value ? (
        <TextSnippetOutlined
          sx={{ color: 'grey.700', cursor: 'pointer' }}
          onClick={() => handleClickReportPdf(params.row as Patient)}
        />
      ) : (
        <Typography variant="caption">-</Typography>
      );
    },
  },
];

export const CREATE_APPOINTMENT_PAGE_TITLE = 'Create Patient Appointment';
