import { api } from 'redux/api';
import { logout, setIsAuthenticated } from './slice';
import {
  AuthResponse,
  LoginRequest,
  LoginResponse,
  VerifyCodeRequest
} from './types';
import { setToStorage } from 'utils/localStorage';
import { HAS_JWT_REFRESH_TOKEN } from 'features/auth/constants';
import { authStorage } from 'utils/authStorage';
import { authApiUrls } from './constants';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<string, LoginRequest>({
      query: (credentials) => ({
        url: authApiUrls.login,
        method: 'POST',
        body: credentials,
      }),
    transformResponse: (data: LoginResponse) => data.phone_number,
    }),

    verifyCode: builder.mutation<AuthResponse, VerifyCodeRequest>({
      query: (credentials) => ({
        url: authApiUrls.verifyCode,
        method: 'POST',
        body: {
          email: credentials.email,
          password: credentials.password,
          verification_code: credentials.code,
        },
      }),
    onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          authStorage.accessToken = data.access;
          setToStorage(HAS_JWT_REFRESH_TOKEN, true);
          dispatch(setIsAuthenticated(true));
        } catch (error) {
          // TODO replace with setting an app error in redux
          console.log(error);
        }
      },
    }),

    refreshToken: builder.mutation<AuthResponse, void | null>({
      query: () => ({
        url: authApiUrls.refreshToken,
        method: 'POST',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          authStorage.accessToken = data.access;
          setToStorage(HAS_JWT_REFRESH_TOKEN, true);
          dispatch(setIsAuthenticated(true));
        } catch (error) {
          // TODO replace with setting an app error in redux
          console.log(error);
        }
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: authApiUrls.logout,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(logout());
          authStorage.accessToken = '';
          setToStorage(HAS_JWT_REFRESH_TOKEN, false);
          dispatch(setIsAuthenticated(false));
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
  useVerifyCodeMutation,
} = authApi;
