import React, { useEffect } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { routes } from 'routes';
import { useLoginMutation } from 'redux/auth/api';
import { useAppSelector } from 'redux/hooks';
import {
  selectApiFieldsValidationErrors,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';
import { ErrorBox } from 'components/errorBox';
import { AuthLayout } from 'components/authLayout';
import { EMAIL_VALIDATION_REGEX } from './constants';

type LoginFormData = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
      formState: { errors },
      watch,
  } = useForm<LoginFormData>({
    mode: 'all',
  });

  const email = watch('email');
  const password = watch('password');
    
  const fieldValidationErrors = useAppSelector(selectApiFieldsValidationErrors);
  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  const [login, { isSuccess, data: phoneLastDigits }] = useLoginMutation();

  const onSubmit = (data: LoginFormData) => {
    login({
      email: data.email,
      password: data.password,
    });
  };

  useEffect(() => {
    if (isSuccess && phoneLastDigits) {
        navigate(routes.verify, {state: { phoneLastDigits, email, password} });
    }
  }, [isSuccess, navigate, phoneLastDigits, email, password]);


  return (
    <AuthLayout>
      <Typography variant="h4" align="center">
        Clinical Approval Dashboard
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {requestValidationError && (
            <ErrorBox message={requestValidationError} />
          )}
          <TextField
            {...register('email', {
              required: 'Email is a required field.',
              pattern: {
                value: EMAIL_VALIDATION_REGEX,
                message: 'Email is not valid.',
              },
            })}
            placeholder="Email"
            type="email"
            error={!!fieldValidationErrors?.email || !!errors.email}
            helperText={fieldValidationErrors?.email || errors?.email?.message}
          />

          <TextField
            {...register('password', {
              required: 'Password is a required field.',
            })}
            placeholder="Password"
            type="password"
            error={!!fieldValidationErrors?.password || !!errors.password}
            helperText={
              fieldValidationErrors?.password || errors?.password?.message
            }
          />

          <Button type="submit">Log in</Button>
        </Stack>
      </form>
    </AuthLayout>
  );
};
