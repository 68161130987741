export const routes = {
  login: '/login',
  verify: '/verify',
  dashboard: '/dashboard',
  setupAccount: '/setup-account',
  createAppointment: '/create-appointment',
};

export const createAccountSubroutes = {
  setPassword: 'set-password',
  setPhone: 'set-phone', 
  confirmCode: 'comfirm-code',
};
