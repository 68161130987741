import React from 'react';
import { Button, Stack, Switch, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InfoOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { routes } from 'routes';

interface ControlLineProps {
  myPatientOnly: boolean;
  handleMyPatientsOnlyToggle: () => void;
}
export const ControlLine: React.FC<ControlLineProps> = ({
  myPatientOnly,
  handleMyPatientsOnlyToggle,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ m: '8px', padding: '17px 0' }}
    >
      <Button
        onClick={() => navigate(routes.createAppointment)}
        startIcon={<AddIcon />}
      >
        New appointment
      </Button>
      <Stack direction="row" spacing={1} alignItems="center">
        <InfoOutlined />
        <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
          Click on the Report Status badge to approve or update reports
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={{ fontSize: '12px' }}>Only see my patients</Typography>
        <Switch checked={myPatientOnly} onChange={handleMyPatientsOnlyToggle} />
      </Stack>
    </Stack>
  );
};
