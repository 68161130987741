import React, { useCallback, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { createAccountSubroutes, routes } from 'routes';
import { AUTH_FLOW_PAGES_TITLE } from './constants';
import { AuthLayout } from 'components/authLayout';
import { ConfirmCode, ConfirmCodeFormData } from 'components/confirmPhoneCode';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAccountSetupPassword,
  selectAccountSetupPhoneNumber,
  selectActivationUrlParams,
} from 'redux/accountSetup/slice';
import { useActivateAccountMutation } from 'redux/accountSetup/api';
import {
  displayErrorPopup,
  selectApiRequestValidationError,
} from 'redux/notifications/slice';
import { DEFAULT_ERROR_MESSAGE } from 'redux/notifications/constants';

export type ConfirmCodeProps = {
  onBackClickCallback: () => void;
};

export const SignupConfirmCode: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activationUrlParams = useAppSelector(selectActivationUrlParams);
  const password = useAppSelector(selectAccountSetupPassword);
  const phoneNumber = useAppSelector(selectAccountSetupPhoneNumber);

  useEffect(() => {
    if (!activationUrlParams || !password || !phoneNumber) {
      dispatch(displayErrorPopup(DEFAULT_ERROR_MESSAGE));
      navigate(routes.login);
    }
  }, [activationUrlParams, password, phoneNumber, dispatch, navigate]);

  const [activateAccount, { isSuccess, isLoading }] =
    useActivateAccountMutation();

  const onSubmitCallback = useCallback(
    (data: ConfirmCodeFormData) => {
      activateAccount({
        password,
        phoneNumber,
        ...data,
        ...activationUrlParams,
      });
    },
    [activateAccount, activationUrlParams, password, phoneNumber]
  );

  const requestValidationError = useAppSelector(
    selectApiRequestValidationError
  );

  useEffect(() => {
    if (requestValidationError) {
      dispatch(displayErrorPopup(requestValidationError));
    }
  }, [dispatch, requestValidationError]);

  const onBackClickCallback = useCallback(() => {
    navigate(`${routes.setupAccount}/${createAccountSubroutes.setPhone}`);
  }, [navigate]);

  return (
    <>
      {isSuccess ? (
        <AuthLayout>
          <Typography variant="h4" align="center">
            {AUTH_FLOW_PAGES_TITLE}
          </Typography>
          <Typography variant="body2" align="center">
            <b>Account creation successfully completed!</b>
          </Typography>
          <Button onClick={() => navigate(routes.login)}>Log in</Button>
        </AuthLayout>
      ) : (
        <ConfirmCode
          onSubmitCallback={onSubmitCallback}
          onBackClickCallback={onBackClickCallback}
          lastPhoneDigits={phoneNumber.slice(-4)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
