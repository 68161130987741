import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NotificationsState } from './types';

const initialNotificationsState: NotificationsState = {
  notificationPopupMessage: undefined,
  apiError: undefined,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialNotificationsState,
  reducers: {
    displayStickyInfoPopup: (state, action: PayloadAction<string>) => {
      state.notificationPopupMessage = {
        message: action.payload,
        autoHide: false,
        type: 'info',
      };
    },
    displayInfoPopup: (state, action: PayloadAction<string>) => {
      state.notificationPopupMessage = {
        message: action.payload,
        autoHide: true,
        type: 'info',
      };
    },
    displayErrorPopup: (state, action: PayloadAction<string>) => {
      state.notificationPopupMessage = {
        message: action.payload,
        autoHide: true,
        type: 'error',
      };
    },
    clearPopup: (state) => {
      state.notificationPopupMessage = undefined;
    },
    clearApiErrors: (state) => {
      state.apiError = undefined;
    },
    setApiRequestValidationError: (state, action: PayloadAction<string>) => {
      state.apiError = {
        ...state.apiError,
        requestValidationMessage: action.payload,
      };
    },
    setApiFieldsValidationError: (
      state,
      action: PayloadAction<Record<string, []>>
    ) => {
      state.apiError = {
        ...state.apiError,
        fieldsValidationMessages: action.payload,
      };
    },
  },
});

export const {
  displayInfoPopup,
  displayErrorPopup,
  displayStickyInfoPopup,
  clearPopup,
  clearApiErrors,
  setApiRequestValidationError,
  setApiFieldsValidationError,
} = notificationsSlice.actions;

export const selectNotificationPopupMessage = (state: RootState) => {
  return state.notifications.notificationPopupMessage;
};

export const selectApiRequestValidationError = (state: RootState) => {
  return state.notifications?.apiError?.requestValidationMessage;
};

export const selectApiFieldsValidationErrors = (state: RootState) => {
  return state.notifications?.apiError?.fieldsValidationMessages;
};

export const notificationsReducer = notificationsSlice.reducer;
