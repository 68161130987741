// After a successful authentication, the API server sets a cookie called 'tuune_refresh'
// that is available only for http requests back to the API server because it's being set as 'httpOnly'

// Therefore, the app needs to keep track on its own about the existence of
// a refresh cookie, so that any subsequent page load trying to access a private route
// will trigger a JWT refresh API call to get a new access token

// Also, since this key is deleted on logout, this helps prevening making unnecessary
// JWT refresh API calls when the user wasn't previously logged int
export const HAS_JWT_REFRESH_TOKEN = 'isLoggedIn';

export const EMAIL_VALIDATION_REGEX =
  /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]{2,63})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const PASSWORD_REQUIRE_NUMBERS_REGEX = /[0-9]/;

export const PASSWORD_REQUIRE_LETTERS_REGEX = /[a-zA-Z]/;

export const AUTH_FLOW_PAGES_TITLE = 'Clinical Approval Dashboard';
