import {
  Color,
  createTheme,
  Palette,
  responsiveFontSizes,
} from '@mui/material';
import { colors } from './palette';
import { fontFamily, fontSizes } from './typography';

// list you custom colors here
type CustomColorKey = 'purple' | 'black' | 'white' | 'yellow' | 'green' | 'red';

declare module '@mui/material/styles/createPalette' {
  type CustomPaletteColors = {
    [K in CustomColorKey]: Palette['primary'] & Partial<Color>;
  };

  interface Palette extends CustomPaletteColors {}

  interface PaletteOptions extends CustomPaletteColors {}
}

// Extend color prop on components
declare module '@mui/material' {
  type CustomPaletteColors = {
    [K in CustomColorKey]: true;
  };

  export interface ButtonPropsColorOverrides extends CustomPaletteColors {}

  export interface TextFieldPropsColorOverrides extends CustomPaletteColors {}
  export interface SwitchPropsColorOverrides extends CustomPaletteColors {}
  export interface RadioPropsColorOverrides extends CustomPaletteColors {}
  export interface AppBarPropsColorOverrides extends CustomPaletteColors {}
  export interface ChipPropsColorOverrides extends CustomPaletteColors {}
  export interface CheckboxPropsColorOverrides extends CustomPaletteColors {}

  export interface LinearProgressPropsColorOverrides
    extends CustomPaletteColors {}
  export interface CircularProgressPropsColorOverrides
    extends CustomPaletteColors {}
  export interface BadgePropsColorOverrides extends CustomPaletteColors {}
}

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily,
      fontWeightBold: 600,
      fontWeightMedium: 400,
      fontWeightLight: 300,
      ...fontSizes,
    },
    palette: {
      // this black & white colors are defined for mui components default styles
      // in sx prop you can use `common.black` or `common.white`
      black: {
        main: colors.black[900],
        light: colors.black[50],
        dark: colors.black[900],
        contrastText: colors.white[900],
      },
      white: {
        main: colors.white[900],
        light: colors.white[700],
        dark: colors.white[50],
        contrastText: colors.black[900],
      },
      purple: {
        main: colors.purple[900],
        light: colors.purple[50],
        dark: colors.purple[900],
        contrastText: colors.white[900],
        // in sx props you can use `purple.600`, `purple.300`, etc.
        ...colors.purple,
      },
      yellow: {
        main: colors.yellow[600],
        light: colors.yellow[50],
        dark: colors.yellow[900],
        contrastText: colors.black[900],
        ...colors.yellow,
      },
      green: {
        main: colors.green[800],
        light: colors.green[50],
        dark: colors.green[900],
        contrastText: colors.white[900],
        ...colors.green,
      },
      red: {
        main: colors.red[800],
        light: colors.red[50],
        dark: colors.red[900],
        contrastText: colors.white[900],
        ...colors.red,
      },
      grey: colors.grey,
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'purple',
        },
        styleOverrides: {
          root: {
            padding: '0 24px',
            borderRadius: '24px',
            height: '45px',
            letterSpacing: 1.6,
          },
          outlined: ({ ownerState: { color = 'purple' }, theme }) => {
            const buttonColor = color as keyof Palette;
            const paletteColor = theme.palette[buttonColor];
            const borderColor =
              typeof paletteColor == 'object' && 'main' in paletteColor
                ? paletteColor.main
                : undefined;
            return {
              ...(![
                'inherit',
                'primary',
                'secondary',
                'error',
                'success',
                'info',
                'warning',
              ].includes(color) &&
                borderColor && {
                  border: `1px solid ${borderColor}`,
                }),
            };
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          color: 'purple',
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          color: 'purple',
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            width: '36px',
            height: '20px',
            padding: '2px 0',
            overflow: 'visible',
          },
          switchBase: {
            color: colors.grey[100],
            padding: 0,
            width: '20px',
            height: '20px',
            '&.Mui-checked': {
              color: colors.grey[100],
              transform: 'translateX(16px)',
              '&+.MuiSwitch-track': {
                opacity: 1,
              },
            },
          },
          track: {
            height: '16px',
            width: '36px',
            borderRadius: '8px',
            boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.25)',
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'purple',
        },
      },
      MuiAppBar: {
        defaultProps: {
          color: 'white',
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderBottom: `1px solid ${colors.grey[300]}`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '24px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '16px',
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'purple',
          disableRipple: true,
          inputProps: {
            color: 'textSecondary',
          },
        },
      },
      MuiFormControlLabel: {
        defaultProps: {
          componentsProps: {
            typography: {
              color: 'textSecondary',
            },
          },
        },
        styleOverrides: {
          root: {
            userSelect: 'none',
          },
        },
      },
    },
  })
);

export * from './globalStyles';
