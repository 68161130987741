import React from 'react';
import { Box, Container, Divider, Stack } from '@mui/material';
import { Logo } from 'components/logo';

interface AuthLayoutProps {
  children: React.ReactNode;
}
export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: '80px',
      }}
    >
      <Stack spacing={3} sx={{ width: '358px' }}>
        <Box display="flex" justifyContent="center">
          <Logo />
        </Box>
        <Divider />
        {children}
      </Stack>
    </Container>
  );
};
